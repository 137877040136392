import { render, staticRenderFns } from "./Keys.vue?vue&type=template&id=34b63470&scoped=true&lang=pug&"
import script from "./Keys.vue?vue&type=script&lang=js&"
export * from "./Keys.vue?vue&type=script&lang=js&"
import style0 from "./Keys.vue?vue&type=style&index=0&id=34b63470&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34b63470",
  null
  
)

export default component.exports
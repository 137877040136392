<template lang="pug">
.keys
  .keys__category
    Select.select-category(
      defaultText="Selecionar Categoria"
      :options="takeCategories"
      type='drop'
      @emit="doSetCategorySelected"
    )

  .keys.keys__sides(
    v-if="categorySelected && takeWinnersKeys[0].length"
  )
    h2 Losers

    h2 Winners

  .keys.keys__sides(
    v-if="categorySelected && takeWinnersKeys[0].length"
  )
    TournamentBrackets(
      side="Left"
      :bracketSize="takeLosersKeys[0].length"
      :items="takeLosersKeys"
    )

    TournamentBrackets(
      side="Right"
      :bracketSize="takeWinnersKeys[0].length"
      :items="takeWinnersKeys"
    )

  h3.keys__empty(
    v-else-if="!categorySelected"
  ) Selecione uma Categoria

  h3.keys__empty(
    v-else
  ) Essa categoria não possui chaves geradas
</template>

<script>

import {
  mapGetters,
  mapActions
} from 'vuex'

import {
  TournamentBrackets,
  Select
} from '@/components/atoms'

export default {
  name: 'Keys',
  props: {},
  data: () => ({
    categorySelected: ''
  }),
  created () {
    this.setCategories()
  },
  components: {
    TournamentBrackets,
    Select
  },
  computed: {
    ...mapGetters('categories', [
      'getCategories'
    ]),
    ...mapGetters('fights', [
      'getCurrentKeys'
    ]),
    takeCategories () {
      return [...this.getCategories].map(category => { return { text: category.name, value: category.id } })
    },
    takeWinnersKeys () {
      if (this.getCurrentKeys.winners && this.getCurrentKeys.winners.length) {
        return this.getCurrentKeys.winners
      }
      return [[]]
    },
    takeLosersKeys () {
      if (this.getCurrentKeys.losers && this.getCurrentKeys.losers.length) {
        return this.getCurrentKeys.losers
      }
      return [[]]
    }
  },
  methods: {
    ...mapActions('categories', [
      'setCategories'
    ]),
    ...mapActions('fights', [
      'setCategoryKeys'
    ]),
    doSetCategorySelected (e) {
      this.categorySelected = e.value
      this.setCategoryKeys({ event_id: 1, category_id: this.categorySelected })
    }
  },
  filters: {},
  watch: {}
}
</script>

<style lang="sass" scoped>
  .keys
    display: flex
    flex-direction: column
    justify-content: space-between
    &__category
      margin: 32px 0 0 0
      display: flex
      width: 100%
      align-items: flex-start
      justify-content: flex-end
      height: 104px
      .select-category
        height: 100%
        justify-content: flex-start

    &__sides
      width: 100%
      overflow: auto
      flex-direction: row

    &__empty
      display: flex
      width: 100%
      justify-content: center
      align-items: center
      color: #C4C4C4
      font-size: 24px
</style>
